.btnContactanos{
    text-decoration: none;
    width: 13.6rem;
    height: 4rem;
    background-color: var(--violet-dark-web-space);
    border-radius: 1.4rem;
    display: grid;
    place-items: center;
    transition: all 0.1s ease-in;
}

.btnContactanos p{
    color: #FFFFFF;
    font-size: 1.8rem;
    font-family: "Akzidenz-Grotesk Pro Med";
}

.btnContactanos:hover:active {
    transform: scale(1.05);
}

@media (min-width: 1024px){
    .btnContactanos{
        width: 26.4rem;
        height: 7.2rem;
        border-radius: 2rem;
        background: var(--violet-dark-web-space);
        border: 0.3rem solid var(--violet-dark-web-space);
        transition: background 0.2s ease-in, color 0.2s ease-in, border-color 0.2s ease-in;
    }

    .btnContactanos p{
        font-size: 3.2rem;
    }

    .btnContactanos:hover {
        background: var(--violet-dark-gradient-opacity10-web-space);
        color: var(--violet-dark-web-space);
        border-color: var(--violet-dark-web-space);
        cursor: pointer;
    }

    .btnContactanos:hover p {
        color: var(--violet-dark-web-space);
    }
}