.btnContactanos {
  background-color: var(--violet-dark-web-space);
  border-radius: 1.4rem;
  place-items: center;
  width: 13.6rem;
  height: 4rem;
  text-decoration: none;
  transition: all .1s ease-in;
  display: grid;
}

.btnContactanos p {
  color: #fff;
  font-family: Akzidenz-Grotesk Pro Med;
  font-size: 1.8rem;
}

.btnContactanos:hover:active {
  transform: scale(1.05);
}

@media (width >= 1024px) {
  .btnContactanos {
    background: var(--violet-dark-web-space);
    border: .3rem solid var(--violet-dark-web-space);
    border-radius: 2rem;
    width: 26.4rem;
    height: 7.2rem;
    transition: background .2s ease-in, color .2s ease-in, border-color .2s ease-in;
  }

  .btnContactanos p {
    font-size: 3.2rem;
  }

  .btnContactanos:hover {
    background: var(--violet-dark-gradient-opacity10-web-space);
    color: var(--violet-dark-web-space);
    border-color: var(--violet-dark-web-space);
    cursor: pointer;
  }

  .btnContactanos:hover p {
    color: var(--violet-dark-web-space);
  }
}
/*# sourceMappingURL=index.de1f3122.css.map */
